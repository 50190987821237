.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  z-index: 1000;
  width: 80%;
  max-width: 600px;
  height: 60vh;
  max-height: 400px;
  background: url('../images/invitationCover.png') center/cover;
}

.popup-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.popup-content > p {
  font-family: "Dancing Script", cursive;
  font-weight: 700;
}

button {
  background-color: #ba502a;
  color: #fff;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #9c4cb7;
}
