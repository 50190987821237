.countdown {
  text-align: center;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.countdown h2 {
  font-size: 24px;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, Sans-serif;
  font-weight: 700;
}

.countdown p {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin: 0;
}

.countdown p span {
  background-color: #a245c1;
  color: #fff;
  border-radius: 5px;
  display: inline-block;
  width: 100px;
  font-size: 18px;
  margin: 2px 5px;
  text-align: center;
  padding: 5px;
}
